import React from "react";
import Link from "next/link";

export default function FooterList ( { link, name, target } )
{
  return (
    <>
      <Link href={ link } passHref>
        <a className="text-white truncate hover:underline cursor-pointer" target={ target }>
          { name }
        </a>
      </Link>
    </>
  );
}
